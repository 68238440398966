<template>
  <router-view />
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, onBeforeMount, onUnmounted } from "vue";
import { mainStore } from "@/store/store.js";

const store = mainStore();

onBeforeMount(() => {
  store.beforeinstallprompt();
});
// localStorage.clear();
onMounted(() => {
  const router = useRouter();
  const isPwa = store.checkDisplay();
  const isLink = localStorage.getItem("link");
  const link = "https://ida.wake-app.net/ggndGn";
  const params = new URLSearchParams(document.location.search);

  if (!localStorage.getItem("params")) {
    localStorage.setItem("params", window.location.search);
  }

  const showOffer = () => {
    router.replace("/offer");
  };

  const showGame = () => {
    router.replace("/game");
  };

  const notOpenlyPwa = () => {
    localStorage.removeItem("installed");
  };

  const startApp = () => {
    const offer_link = link + localStorage.getItem("params");
    localStorage.setItem("link", offer_link);

    if (
      localStorage.getItem("showOffer") ||
      (isPwa && store.installed && isLink)
    ) {
      showOffer();
    } else if (!isPwa) {
      if (params.get("redirect")) {
        localStorage.setItem("openStore", true);
      }
      notOpenlyPwa();
      showGame();
    } else {
      params.get("redirect");
      if (params.get("redirect")) {
        localStorage.setItem("openStore", true);
      }
      showGame();
    }
  };

  startApp();
});
</script>

<style lang="scss" scoped></style>
