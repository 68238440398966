<template>
  <div class="store">
    <div class="page">
      <div class="store-wrap withvideo">
        <section id="video-header" class="cover" v-if="withvideo">
          <div class="play" @click="videoOverlayStatus = true">
            <i class="material-icons-outlined">play_arrow</i> Trailer
          </div>
          <video
            autoplay
            muted
            loop
            crossorigin
            playsinline
            @loadeddata="videoLoaded"
          >
            <source
              :src="`bananza.mp4`"
              type="video/mp4"
              @error="withvideo = false"
            />
          </video>
        </section>
        <section
          id="img-cover"
          class="cover"
          v-else-if="!withvideo && withcover"
        >
          <picture>
            <source
              :srcset="`/uploads/${theme}/images/cover?type=webp&w=720`"
              type="image/webp"
            />
            <source
              :srcset="`/uploads/${theme}/images/cover?w=720`"
              type="image/jpeg"
            />
            <img
              :src="`/uploads/${theme}/images/cover?w=720`"
              @error="withcover = false"
            />
          </picture>
        </section>

        <a href="" ref="gotochrome" style="display: none"></a>
        <header id="app-title" class="container">
          <div class="img">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="img-container">
              <img src="../assets/logo.png" alt="Sweet Bobanza" />
            </div>
          </div>
          <div id="app-name">
            <h2>Sweet Bonanza</h2>
            <span>Casino App.</span>
            <div><span>Contains ads</span><span>In-app purchases</span></div>
          </div>
        </header>
        <section id="app-stats" class="container">
          <div class="item">
            <div class="icon">4.9<span>★</span></div>
            <div class="desc">120K reviews</div>
          </div>
          <div class="item">
            <div class="icon">510К+</div>
            <div class="desc">Downloads</div>
          </div>
          <div class="item">
            <div class="icon">
              <i class="material-icons-outlined icon18">18_up_rating</i>
            </div>
            <div class="desc">Rated for 18+</div>
          </div>
        </section>
        <section id="download-sec">
          <div class="container">
            <button id="more-info" class="button none">More info</button>

            <button
              v-if="
                !store.prompt &&
                !store.loading &&
                !store.installed &&
                userDevice == 'Android'
              "
              id="install"
              class="button"
            >
              <span>Preparing...</span>
            </button>

            <button v-if="userDevice != 'Android'" id="install" class="button">
              <span>The device is not supported </span>
            </button>

            <button
              v-if="store.prompt && userDevice == 'Android'"
              @click="installApp"
              id="install"
              class="button"
            >
              <span v-if="!loading">Install</span>
              <span v-if="loading">Installing...</span>
            </button>

            <button
              v-if="
                !store.prompt &&
                !store.loading &&
                store.installed &&
                userDevice == 'Android'
              "
              @click="openApp"
              id="install"
              class="button"
            >
              <span>open App</span>
            </button>

            <button @click="showNotice" id="how-to">How to install</button>
          </div>
        </section>
        <section id="img-gallery">
          <div
            class="grid"
            style="grid-template-columns: repeat(4, max-content)"
          >
            <a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/1.jpg" type="image/webp" />
                <source srcset="../assets/1.jpg" type="image/jpeg" />
                <img src="../assets/1.jpg" /></picture></a
            ><a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/2.jpg" type="image/webp" />
                <source srcset="../assets/2.jpg" type="image/jpeg" />
                <img src="../assets/2.jpg" /></picture></a
            ><a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/3.jpg" type="image/webp" />
                <source srcset="../assets/3.jpg" type="image/jpeg" />
                <img src="../assets/3.jpg" /></picture></a
            ><a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/4.jpg" type="image/webp" />
                <source srcset="../assets/4.jpg" type="image/jpeg" />
                <img src="../assets/4.jpg" /></picture
            ></a>
          </div>
        </section>
        <div id="img-overlay" class=""><img src="" /></div>
        <section id="about-sec">
          <div class="container">
            <div class="sec-title">
              <h3>About this app</h3>
              <button @click="showAbout" class="more_btn btn-icon">
                <i class="material-icons-outlined">arrow_forward</i>
              </button>
            </div>
            <div class="app-short-desc">
              😱DON'T MISS A CHANCE - 100,000,00 $ 🐺 Yo Yo Yo Sweet Bobanza is
              in touch! Download the app and get 250 $ directly on your card! 💸
            </div>
            <div class="app-meta">
              <span> #10 Casino </span><span> Gambling </span>
            </div>
          </div>
        </section>
        <div ref="aboutRef" id="full-desc" class="fixed-screen">
          <header>
            <button @click="showAbout" class="back_btn btn-icon">
              <i class="material-icons-outlined">arrow_back</i>
            </button>
            <div class="img">
              <img src="../assets/logo.png" alt="Sweet Bobanza" />
            </div>
            <div class="name">
              <div class="title-aps">Sweet Bobanza</div>
              <span>Details</span>
            </div>
          </header>
          <div class="container">
            <h3 class="title-aps">About this app</h3>
            <div class="text">
              <p>😱DON'T MISS A CHANCE - 100,000,00 $</p>
              <p>
                🐺 Yo Yo Yo Sweet Bobanza is in touch! Download the app and get
                2500 $ directly on your card! 💸
              </p>
              <p>
                🤑 My developers team created this casino, where I simply give
                hundreds of millions of dollars to anyone who wishes!
              </p>
              <p>
                Especially for you, I have compiled the best slots and games
                from all casinos in the world:
              </p>
              <p>🍬Sweet Bonanza</p>
              <p>📖Book of Ra</p>
              <p>⚡Gates of Olympus</p>
              <p>🔮Plinko</p>
              <p>🐶Dog House</p>
              <p>🍭Sugar Rush</p>
              <p>🐺Wolf Gold and many others!</p>
            </div>
          </div>
          <div class="about-app">
            <div class="container">
              <div class="title-aps">App info</div>
              <div class="details-grid">
                <div class="row">
                  <div class="name">Version</div>
                  <span>1.5.1</span>
                </div>
                <div class="row">
                  <div class="name">Updated on</div>
                  <span>Sep 12, 2023</span>
                </div>
                <div class="row">
                  <div class="name">Downloads</div>
                  <span>512400+ downloads</span>
                </div>
                <div class="row">
                  <div class="name">Download size</div>
                  <span>32.16 MB</span>
                </div>
                <div class="row">
                  <div class="name">Developer</div>
                  <span>Casino App.</span>
                </div>
                <div class="row">
                  <div class="name">Released on</div>
                  <span>Feb 4, 2023</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="ratings">
          <div class="container">
            <div class="sec-title">
              <h3>
                Ratings and reviews<a
                  href="https://play.google.com/about/comment-posting-policy/"
                  target="_blank"
                  ><i class="material-icons-outlined">info</i></a
                >
              </h3>
              <button @click="showReviews" class="more_btn btn-icon">
                <i class="material-icons-outlined">arrow_forward</i>
              </button>
            </div>
            <div class="rating">
              <div class="left">
                <h2>4.9</h2>
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="half">★</span>
                </div>
                <p>123012</p>
              </div>
              <div class="right">
                <div class="row">
                  <span>5</span>
                  <div class="line">
                    <i style="width: 95%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>4</span>
                  <div class="line">
                    <i style="width: 80%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>3</span>
                  <div class="line">
                    <i style="width: 48%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>2</span>
                  <div class="line">
                    <i style="width: 24%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>1</span>
                  <div class="line">
                    <i style="width: 10%"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="reviews">
          <div class="container reviews-grid">
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Noah.jpg"
                    alt="Noah"
                    loading="lazy"
                  />
                </div>
                <div class="name">Noah</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">14.11.2023</div>
              </div>
              <div class="text">
                Thank you !!! 🔥🔥🔥 Thanks to you, I won 10,00 $ in just a few
                hours! I can't believe my eyes... We are waiting for the video!
              </div>
              <div class="developer-answer">
                <div class="title-aps">Casino App. <span>14.07.2023</span></div>
                <div class="text">
                  Thanks for the feedback! We are glad that you like our app
                </div>
              </div>
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(183, 229, 47)">M</span>
                </div>
                <div class="name">Mason</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">10.07.2023</div>
              </div>
              <div class="text">
                I didn't believe the guys at first, but I downloaded it. A week
                has passed and there are already 120,00 $ in my account. I
                haven't deleted it yet🤤👍👍
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Michael.jpg"
                    alt="Michael"
                    loading="lazy"
                  />
                </div>
                <div class="name">Michael</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">02.07.2023</div>
              </div>
              <div class="text">
                You are a genius! I finally paid the loan for the car, I think I
                will buy a new Porsche soon 🤑🤑🤑
              </div>
              <!---->
            </div>
            <a href="javascript:void(0);" @click="showReviews" id="all-reviews"
              >See all reviews</a
            >
          </div>
        </section>
        <div ref="fullReviewsRef" id="full-reviews" class="fixed-screen">
          <header>
            <button @click="showReviews" class="back_btn btn-icon">
              <i class="material-icons-outlined">arrow_back</i>
            </button>
            <div class="img">
              <img
                src="../assets/logo.png"
                alt="Sweet Bobanza"
                loading="lazy"
              />
            </div>
            <div class="name">
              <div class="title-aps">
                Sweet Bobanza
                <div class="rating"><span></span></div>
              </div>
              <span>Ratings and reviews</span>
            </div>
          </header>
          <div class="filter">
            <div class="container">
              <div class="left">All</div>
              <div class="right">
                Most relevant<i class="material-icons-outlined">sort</i>
              </div>
            </div>
          </div>
          <div class="container reviews-grid">
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Noah.jpg"
                    alt="Noah"
                    loading="lazy"
                  />
                </div>
                <div class="name">Noah</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">14.11.2023</div>
              </div>
              <div class="text">
                Thank you !!! 🔥🔥🔥 Thanks to you, I won 10,00 $ in just a few
                hours! I can't believe my eyes... We are waiting for the video!
              </div>
              <div class="developer-answer">
                <div class="title-aps">Casino App. <span>14.07.2023</span></div>
                <div class="text">
                  Thanks for the feedback! We are glad that you like our app
                </div>
              </div>
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(183, 229, 47)">M</span>
                </div>
                <div class="name">Mason</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">10.07.2023</div>
              </div>
              <div class="text">
                I didn't believe the guys at first, but I downloaded it. A week
                has passed and there are already 120,00 $ in my account. I
                haven't deleted it yet🤤👍👍
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Michael.jpg"
                    alt="Michael"
                    loading="lazy"
                  />
                </div>
                <div class="name">Michael</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">02.07.2023</div>
              </div>
              <div class="text">
                You are a genius! I finally paid the loan for the car, I think I
                will buy a new Porsche soon 🤑🤑🤑
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(207, 73, 73)">W</span>
                </div>
                <div class="name">William</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">24.06.2023</div>
              </div>
              <div class="text">
                I don't understand all the excitement around this app, I could
                only win 200 $ 🥲
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(68, 163, 193)">A</span>
                </div>
                <div class="name">Agnes</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">14.06.2023</div>
              </div>
              <div class="text">
                This is the best casino I've ever played!!!!
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Juliet.jpg"
                    alt="Juliet"
                    loading="lazy"
                  />
                </div>
                <div class="name">Juliet</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">07.06.2023</div>
              </div>
              <div class="text">
                MrBet is the best choice of casino in our region !!!!! Thanks
                developers !!!
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Graham.jpg"
                    alt="Graham"
                    loading="lazy"
                  />
                </div>
                <div class="name">Graham</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">23.05.2023</div>
              </div>
              <div class="text">
                OMG, just hit the jackpot at Mr.Bet! 💰 Fast cashouts, loads of
                bonuses. You gotta try this, mates!
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(193, 155, 68)">H</span>
                </div>
                <div class="name">Hugh</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">17.05.2023</div>
              </div>
              <div class="text">
                Played at Mr.Bet last night, mate! 🌟 Quick withdrawals, felt
                like a VIP. Join the fun, don't miss out!
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Martin.jpg"
                    alt="Martin"
                    loading="lazy"
                  />
                </div>
                <div class="name">Martin</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">05.05.2023</div>
              </div>
              <div class="text">
                Mr.Bet is the real deal! 🚀 Fast cash, insane bonuses.
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(193, 68, 188)">S</span>
                </div>
                <div class="name">Scott</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">26.04.2023</div>
              </div>
              <div class="text">
                Had a blast at Mr.Bet! 🎉 Super fast withdrawals, felt like a
                high roller.
              </div>
              <!---->
            </div>
          </div>
        </div>
        <section id="developer" class="">
          <div class="container">
            <div class="sec-title">
              <h3>Developer contact</h3>
              <button class="more_btn btn-icon">
                <i class="material-icons-outlined">expand_more</i>
              </button>
            </div>
            <div class="grid">
              <a href="https://kinghills.com.com/" target="_blank" class="row"
                ><i class="material-icons-outlined">public</i>
                <div class="name">Website</div></a
              ><a href="mailto:https://kinghills.com.com/" class="row"
                ><i class="material-icons-outlined">mail</i>
                <div class="name">
                  Email<span>support@kinghills.com.com</span>
                </div></a
              ><a
                href="https://kinghills.com.com/privacy-policy"
                target="_blank"
                class="row"
                ><i class="material-icons-outlined">shield</i>
                <div class="name">Privacy policy</div></a
              >
            </div>
          </div>
        </section>
        <footer id="footer">
          <div class="container">
            <div class="sec-title"><h3>Google</h3></div>
            <div class="links">
              <a
                href="https://play.google.com/intl/en_en/about/play-terms/index.html"
                target="_blank"
                >Terms of Service</a
              ><a href="https://policies.google.com/privacy" target="_blank"
                >Privacy policy</a
              ><a href="http://developer.android.com/index.html" target="_blank"
                >For developers</a
              ><a
                href="https://support.google.com/googleplay/?p=about_play"
                target="_blank"
                >About Google Play</a
              >
            </div>
            <p>©2024 Google</p>
          </div>
        </footer>
        <a href="" style="display: none"></a>
        <div ref="noticeRef" id="notice" class="">
          <div @click="showNotice" class="overlay"></div>
          <div class="wrap">
            <button @click="showNotice" class="btn-icon">
              <i class="material-icons-outlined">close</i>
            </button>
            <div class="container">
              <header>
                <div class="img">
                  <img
                    src="../assets/logo.png"
                    alt="Sweet Bobanza"
                    loading="lazy"
                  />
                </div>
                <div class="meta">
                  <h2>Sweet Bobanza</h2>
                  <p>
                    😱DON'T MISS A CHANCE - 100,000,00 $ 🐺 Yo Yo Yo Mr.Bet
                    Casino is in touch! Download the app and get 250 $ directly
                    on your card! 💸
                  </p>
                </div>
              </header>
              <div class="details-grid">
                <div class="title-aps">App info</div>
                <div class="row">
                  <div class="name">Version</div>
                  <span>1.5.1</span>
                </div>
                <div class="row">
                  <div class="name">Updated on</div>
                  <span>Sep 12, 2023</span>
                </div>
                <div class="row">
                  <div class="name">Downloads</div>
                  <span>512400+ downloads</span>
                </div>
                <div class="row">
                  <div class="name">Download size</div>
                  <span>32.16 MB</span>
                </div>
                <div class="row">
                  <div class="name">Developer</div>
                  <span>Casino App.</span>
                </div>
                <div class="row">
                  <div class="name">Released on</div>
                  <span>Feb 4, 2023</span>
                </div>
              </div>
              <div class="content">
                <p>
                  If you have some problems with installation, you can install
                  this app by adding this page to home screen. You need to
                  select "Add to Home Screen" in your browser settings.
                  <a
                    href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen#how_do_you_use_it"
                    target="_blank"
                    >More info</a
                  >
                </p>
              </div>
            </div>
            <button @click="installApp" class="button">
              Add this website to home screen by pressing "Add to Homescreen"
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import { mainStore } from "@/store/store.js";
import { useRouter } from "vue-router";
const router = useRouter();
let description = ref(false);
let security = ref(false);
let review = ref(false);
const store = mainStore();
const loading = ref(false);
const fullReviewsRef = ref();
const aboutRef = ref();
const noticeRef = ref();
const browserName = ref();
const videoOverlayStatus = ref(false);
const gotochrome = ref();
const userDevice = ref();
const nAgt = navigator.userAgent;
const showOffer = ref(false);
const withvideo = ref(true);
const getUserBrowserName = () => {
  if (nAgt.indexOf("YaBrowser") !== -1) {
    return "YaBrowser";
  } else if (nAgt.indexOf("Opera") !== -1) {
    return "Opera";
  } else if (nAgt.indexOf("MSIE") !== -1) {
    return "MSIE";
  } else if (nAgt.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (nAgt.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (nAgt.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "error";
  }
};
const preparing = () => {
  if (!store.prompt && !store.loading && !store.installed) {
    showOffer.value = true;
  }
};
const getUserDevice = () => {
  if (nAgt.indexOf("Android") !== -1) {
    return "Android";
  } else {
    return "IOS";
  }
};

const videoLoaded = () => {
  withvideo.value = true;
};

userDevice.value = getUserDevice();
browserName.value = getUserBrowserName();

setTimeout(() => {
  if (!store.prompt && !store.loading && !store.installed) {
    localStorage.setItem("showOffer", true);
    router.replace("/offer");
  }
}, 7000);

onBeforeMount(() => {
  if (getUserDevice() != "Android") {
    router.replace("/offer");
  }
});

onMounted(() => {
  if (localStorage.getItem("installed")) {
    router.replace("/offer");
  }
  if (browserName.value != "Chrome" && userDevice.value == "Android") {
    const params = localStorage.getItem("params");
    gotochrome.value.setAttribute(
      "href",
      `intent://navigate?url=${window.location.hostname}/${
        params ? params + "?redirect=true" : "?redirect=true"
      }#Intent;scheme=googlechrome;end;`
    );
    gotochrome.value.click();
  }
});

const showReviews = () => {
  if (fullReviewsRef.value.classList.contains("active")) {
    fullReviewsRef.value.classList.remove("active");
  } else {
    fullReviewsRef.value.classList.add("active");
  }
};

const showAbout = () => {
  if (aboutRef.value.classList.contains("active")) {
    aboutRef.value.classList.remove("active");
  } else {
    aboutRef.value.classList.add("active");
  }
};

const showNotice = () => {
  if (noticeRef.value.classList.contains("active")) {
    noticeRef.value.classList.remove("active");
  } else {
    noticeRef.value.classList.add("active");
  }
};

const installApp = async () => {
  fbq("track", "Lead");
  if (!store.prompt) {
    return;
  }

  loading.value = true;

  const result = await store.prompt.prompt();
  if (result["outcome"] == "accepted") {
    loading.value = true;
    localStorage.setItem("installed", true);

    setTimeout(() => {
      localStorage.setItem("showOffer", true);
      store.installed = true;
      loading.value = false;
      store.prompt = null;
      showOffer.value = true;
      //   router.push("/offer");
    }, 2e3);
  } else {
    loading.value = false;
  }
};

const openApp = () => {
  router.replace("/offer");
};
</script>
