import AndroidStoreView from '@/view/androidStore.vue'
import HomeView from '@/view/homeView.vue'
import OfferView from '@/view/offerView.vue'
import PreloaderView from '@/view/preloaderView.vue'

import { createRouter, createWebHistory } from 'vue-router'
const routes = [
	{
		path: '/',
		component: PreloaderView,
	},
	{
		path: '/game',
		component: HomeView,
	},
	{
		path: '/offer',
		component: OfferView,
	},
	{
		path: '/andr',
		components: AndroidStoreView,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
