import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./routes";
import "./style.scss";

const pinia = createPinia();
const app = createApp(App);

app

  .use(pinia)
  .use(router)
  .mount("#app");


  OneSignal.push(function() {
    OneSignal.init({
      appId: "2d3684b9-2f50-4d60-a05c-cbbfba1e826a",
    });
});
