<template>
  <div v-if="showloader" class="preloader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block"
      width="90px"
      height="90px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(80,50)">
        <g transform="rotate(0)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.875s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.875s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,71.21320343559643)">
        <g transform="rotate(45)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.75s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.75s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(50,80)">
        <g transform="rotate(90)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.625s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.625s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(28.786796564403577,71.21320343559643)">
        <g transform="rotate(135)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9" fill-opacity="0.625">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.5s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.5s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(20,50.00000000000001)">
        <g transform="rotate(180)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9" fill-opacity="0.5">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.375s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.375s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(28.78679656440357,28.786796564403577)">
        <g transform="rotate(225)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9" fill-opacity="0.375">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.25s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.25s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(49.99999999999999,20)">
        <g transform="rotate(270)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9" fill-opacity="0.25">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.125s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.125s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,28.78679656440357)">
        <g transform="rotate(315)">
          <circle cx="0" cy="0" r="6" fill="#E6E1C9" fill-opacity="0.125">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
              values="1;0"
              begin="0s"
            ></animate>
          </circle>
        </g>
      </g>
    </svg>
  </div>

  <div id="app-frame">
    <div v-if="!showOpenApp" class="bg"></div>
    <div v-if="!showOpenApp" class="popup-container">
      <svg
        @click="closeModal"
        width="800px"
        height="800px"
        viewBox="0 0 16 16"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="svg8"
      >
        <metadata id="metadata5">
          <rdf:RDF
            ><cc:Work
              ><dc:format>image/svg+xml</dc:format
              ><dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title /><dc:date
                >2021</dc:date
              ><dc:creator
                ><cc:Agent
                  ><dc:title>Timothée Giet</dc:title></cc:Agent
                ></dc:creator
              ><cc:license
                rdf:resource="http://creativecommons.org/licenses/by-sa/4.0/" /></cc:Work
            ><cc:License
              rdf:about="http://creativecommons.org/licenses/by-sa/4.0/"
              ><cc:permits
                rdf:resource="http://creativecommons.org/ns#Reproduction" /><cc:permits
                rdf:resource="http://creativecommons.org/ns#Distribution" /><cc:requires
                rdf:resource="http://creativecommons.org/ns#Notice" /><cc:requires
                rdf:resource="http://creativecommons.org/ns#Attribution" /><cc:permits
                rdf:resource="http://creativecommons.org/ns#DerivativeWorks" /><cc:requires
                rdf:resource="http://creativecommons.org/ns#ShareAlike" /></cc:License
          ></rdf:RDF>
        </metadata>
        <rect
          transform="rotate(45)"
          ry="0"
          y="-1"
          x="4.3137083"
          height="2"
          width="14"
          id="rect1006"
          style="
            opacity: 1;
            vector-effect: none;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 4;
            stroke-linecap: square;
            stroke-linejoin: round;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 3.20000005;
            stroke-opacity: 1;
          "
        />
        <rect
          transform="rotate(-45)"
          ry="0"
          y="10.313708"
          x="-7"
          height="2"
          width="14"
          id="rect1006-5"
          style="
            opacity: 1;
            vector-effect: none;
            fill: #000;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 4;
            stroke-linecap: square;
            stroke-linejoin: round;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 3.20000005;
            stroke-opacity: 1;
          "
        />
      </svg>
      <img src="../assets/main-logo.svg" alt="Kinghills Casino" />
      <h3 class="app-banner-title">Best in the App</h3>
      <p class="app-banner-description">
        Slots? Live Casino? Whatever your preference, the Casino App will
        simplify your gaming life! Stay connected and play with us no matter
        where you are!
      </p>
      <div class="btn" @click="openApp">Open App</div>
    </div>
    <iframe
      :onload="frameOnLoad"
      id="i_frame"
      :src="link"
      loading="lazy"
      sandbox="allow-scripts allow-same-origin allow-modals allow-forms allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { mainStore } from "@/store/store.js";

const store = mainStore();
const isPwa = store.checkDisplay();
const nAgt = navigator.userAgent;
const getUserDevice = () => {
  if (nAgt.indexOf("Android") !== -1) {
    return "Android";
  } else {
    return "IOS";
  }
};

const showOpenApp = ref(localStorage.getItem("showOpenApp") || true);
fbq("track", "ViewContent");
const openApp = () => {
  localStorage.setItem("showOpenApp", true);
  showOpenApp.value = true;
  window.open(
    window.location.href + (window.location.href.includes("?") ? "&" : "?"),
    "_blank"
  );
};
const showloader = ref(true),
  link = ref(localStorage.getItem("link"));

if (!link) {
  window.location.reload();
}
const frameOnLoad = (res) => {
  showloader.value = false;
};

const closeModal = () => {
  showOpenApp.value = true;
};

onMounted(async () => {
  const html = document.querySelector("html");

  if (getUserDevice() != "Android" && !isPwa) {
    showOpenApp.value = true;
  }
  html.classList.remove("px16");

  //   if (showOpenApp.value && !isPwa && getUserDevice() != "IOS") {
  //     showOpenApp.value = true;
  //     setTimeout(() => {
  //       showOpenApp.value = false;
  //     }, 2000);
  //   }
});
</script>

<style scoped>
#app-frame {
  background: #000;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  svg {
    width: 24px;
    height: 24px;
    fill: #fff;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 999;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .popup-container {
    color: #000;
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: 335px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    padding: 3rem;
    background: #fbfbfb;
    z-index: 999;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 4rem;
    flex-direction: column;

    img {
      max-width: 120px;
    }
  }
}

.app-banner-title {
  color: #000;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.app-banner-description {
  text-align: center;
  margin: 8px 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  color: #a2a5af;
}

.btn {
  color: #ffff;
  padding: 10px 30px;
  white-space: nowrap;
  border-radius: 22px;
  background: #00b394;
}
.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 998;
  background: #0009;
}

.preloader {
  background: #060e2a;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  display: flex;
  z-index: 9000;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 400px;
  }
  svg {
    width: 100%;
    min-width: 100px;
  }
}
</style>
