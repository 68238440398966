import { defineStore } from 'pinia'
import { ref } from 'vue'

export const mainStore = defineStore('main', () => {
	const prompt = ref(null)
	const showStore = ref(false)
	const installed = ref(localStorage.getItem('installed') ?? false)

	const beforeinstallprompt = () => {
		window.addEventListener('beforeinstallprompt', e => {
			e.preventDefault()
			prompt.value = e
		})
	}

	const checkDisplay = () => {
		const display = ['fullscreen', 'standalone', 'minimal-ui'].some(
			displayMode =>
				window.matchMedia('(display-mode: ' + displayMode + ')').matches
		)
		return display
	}

	return {
		showStore,
		checkDisplay,
		prompt,
		installed,
		beforeinstallprompt,
	}
})
